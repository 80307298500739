<template>
  <v-app class="brade-color-primary">
    <v-content>
      <!-- <HelloWorld/> -->
      <router-view :key="$route.fullPath"></router-view>
    </v-content>

    <v-snackbar
      color="red accent-2"
      :timeout="15000"
      right
      top
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import config from "./assets/json/config.json";

import { helperMixin } from "@/mixins/helper.js";

export default {
  name: "App",
  mixins: [helperMixin],
  data: () => ({
    snackbar: false,
    text: "Olá, recomendado que utilize outro navegador, como por exemplo: google chrome",
  }),
  created() {
    let ie = this.isUsingIE();
    if (ie) {
      this.snackbar = true;
    }
  },
};
</script>

<style>
.brade-imgfull .v-card__text {
  padding: 0 !important;
}

.brade-imgfull .v-card__text .v-window__container {
  max-height: calc(100vh - 64px);
  background-color: #e1e1e1;
}

.brade-imgfull
  .v-card__text
  .v-window__container
  .v-image__image.v-image__image--cover {
  max-height: calc(100vh - 64px);
  background-size: contain !important;
}

.fixed-buttons {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
}

.btn-custom {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cotar-frete-link {
  margin-right: 3.9rem;
  text-transform: uppercase;
  font-style: italic;
  border-radius: 0.5rem;
}

.btn-blue {
  background-color: #0d47a1 !important;
  border-color: #fff !important;
  border-radius: 14px;
  color: #fff !important;
}

.whatsapp-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #16cc08;
  color: #fff;
  border-radius: 50%;
  font-size: 32px;
  width: 50px;
  height: 50px;
  text-decoration: none;
}
.brade-color-primary {
  background: linear-gradient(90deg, #120000 0%, #e90000 100%);
}

#app {
  background: #e1e1e1;
}

.container h2.brade-session {
  position: relative;
}

.container h2.brade-session::before {
  content: "";
  display: block;
  border-top: 5px solid #e5173f;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 45%;
  z-index: 1;
}

.container h2.brade-session .brade-titulo-oferta {
  background: #e1e1e1;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.container h2.brade-session .brade-titulo-x2 {
  background: #dfe1e5;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.modal-brade-disputa.v-card.v-sheet.theme--light {
  max-width: 916px !important;
  width: 100% !important;
}
/* @media (max-width: 1800px) and (min-width: 1900px) {
  .container h2.brade-session:after {
    width: 60%;
  }
} */

.brade-line {
  display: flex;
}
.brade-nav-link {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.brade-nav-link a:hover {
  opacity: 1 !important;
}

@media (max-width: 751px) {
  .brade-nav-link {
    display: none;
  }

  .brade-line {
    flex-direction: column;
    align-items: center;
  }

  .brade-left {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }

  .brade-right,
  .brade-timer {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-right: 15px !important;
    width: 100% !important;
  }

  .brade-timer {
    margin-top: 10px;
  }

  .modal-brade-disputa.v-card.v-sheet.theme--light {
    max-width: 97% !important;
    width: 100% !important;
    margin: 0 !important;
  }

  .brade-card.v-card.v-sheet.theme--light {
    margin: 0 0 15px !important;
  }

  .container h2.brade-session::before {
    display: none !important;
  }

  .container h2.brade-session {
    text-align: center;
  }

  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    display: none !important;
  }
}
</style>