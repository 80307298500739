import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginData: null
  },
  mutations: {
    SET_LOGIN(state, resultado) {
      console.log(state, resultado
      )
      state.loginData = resultado;
    },

    SET_UP_LOGIN(state, resultado) {
      state.loginData = resultado;
    },

    SET_ENCERRA_LOGIN(state, resultado) {
      (state.loginData = null)
    },
  },
  actions: {
    setLogin({ commit }, body) {

      let { Login, Senha, xDominio, leilaoCod } = body

      var form_data = new FormData();

      form_data.append("Login", Login);
      form_data.append("Senha", Senha);
      form_data.append("xSession", "111222333");
      form_data.append("xDominio", xDominio);
      form_data.append("xIP", "1.1.1.1");

      return new Promise((resolve, reject) => {

        Vue.prototype.$axios
          .post(`https://wpx.provedor.space/frm2API/Login.aspx?Tipo=BradescoCarbuy&Token=17D8502BB0620AA7565BF02EB417AA3A4355E7EB&LeilaoCod=${leilaoCod}`, form_data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            let body = res.data;
            // console.log(1, body)
            if (body.Retorno.Validacao === "Valido") {
              // console.log(body)
              commit("SET_LOGIN", body);
              sessionStorage.setItem("dados_login", JSON.stringify(body));
              resolve(body);
            } else {
              reject(body.Retorno.Descricao);
            }
          })
          .catch(err => {
            // console.log(err.response.status);
            reject(err);
          })
          .finally(() => {

          });
      });
    },

    updateLogin({ commit }, body) {
      commit("SET_UP_LOGIN", body);
    },

    setEncerraLogin({ commit }, body) {
      commit("SET_ENCERRA_LOGIN", body);
    },
  },
  modules: {
  },

})
