import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "@/components/shared/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:leilaoCod",
    nome: "login",
    component: () => import("../views/Login"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/evento/:name/:hashValmiki/:hashPessoa/:codCliente/:apelido/:loteDetaque?",
    name: "Leilao",
    component: () => import("../views/Eletronico.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/disputa/:name/:hashValmiki/:hashPessoa/:codCliente/:apelido/:loteDetaque?",
    name: "Martelinho",
    component: () => import("../views/Martelinho.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (sessionStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (sessionStorage.getItem("username") == null) {
      next();
    } else {
      next({ name: "/" });
    }
  } else {
    next();
  }
});

export default router;
