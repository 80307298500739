import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import moment from "moment";
import VueToastr from "vue-toastr";
import money from 'v-money'
import VuetifyMask from "vuetify-mask";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(money, { precision: 4 })
Vue.use(VuetifyMask);

Vue.use(moment);
Vue.use(VueToastr, {
  defaultTimeout: 3000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  // defaultType: "error",
  defaultPosition: "toast-bottom-right",
  defaultCloseOnHover: false,
  // defaultStyle: { "background-color": "red" },
  defaultClassNames: ["animated", "zoomInUp"],
  clickClose: true

});

// this.$toastr.s("SUCCESS MESSAGE", "Success Toast Title");
// this.$toastr.e("ERRROR MESSAGE");
// this.$toastr.w("WARNING MESSAGE");
// this.$toastr.i("INFORMATION MESSAGE");





new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
