export const helperMixin = {
    methods: {
        isUsingIE: function () {

            var ieDez = window.navigator.userAgent;
            var msie = ieDez.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ieDez.substring(msie + 5, ieDez.indexOf('.', msie)), 10);
            }

            var ieOnze = window.navigator.userAgent;
            var trident = ieOnze.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ieOnze.indexOf('rv:');
                return parseInt(ieOnze.substring(rv + 3, ieOnze.indexOf('.', rv)), 10);
            }

            var edge = window.navigator.userAgent;
            var edge = edge.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(edge.substring(edge + 5, edge.indexOf('.', edge)), 10);
            }


        },
        gerarQueryString: function (chave = '', array = []) {

            return '&' + array
                .map((value) => `&${chave}=${encodeURIComponent(value)}`)
                .join("&");


        }
    }
}